import Logger from '../utils/Logger';
import { SubscriptionService } from './SubscriptionService';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'https://api.unionarenadecks.com';
const REDIRECT_URI = `${SERVER_URL}/auth/apple/callback`;

const APPLE_CLIENT_ID_WEB = process.env.REACT_APP_APPLE_CLIENT_ID_WEB;

export const AppleAuthService = {
  isAvailable: async (): Promise<boolean> => {
    return true;
  },

  signIn: async (): Promise<{ success: boolean; isSubscribed?: boolean; accessToken?: string; canceled?: boolean }> => {
    try {
      Logger.log('Starting Apple Sign In process...');
      const state = generateRandomState();
      localStorage.setItem('appleAuthState', state);
      const authUrl = `https://appleid.apple.com/auth/authorize?client_id=${APPLE_CLIENT_ID_WEB}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&state=${state}&scope=name%20email&response_mode=form_post`;
      window.location.href = authUrl;
      return { success: false, canceled: false };
    } catch (e) {
      if (Logger.isError(e)) {
        Logger.error('Error during Apple Sign In:', e);
      } else {
        Logger.error('Unknown error during Apple Sign In');
      }
      throw e;
    }
  },

  handleAuthCallback: async (accessToken: string): Promise<{ success: boolean; isSubscribed: boolean; userId: string }> => {
    try {
      Logger.log('Handling Apple Auth callback');

      localStorage.setItem('userToken', accessToken);
      
      // Decode the JWT to get the user ID
      const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
      const userId = decodedToken.id;

      if (userId) {
        localStorage.setItem('userId', userId);
        Logger.log('User ID:', userId);
        Logger.log('Server authentication successful');

        await SubscriptionService.init(userId);
        const isSubscribed = await SubscriptionService.checkSubscriptionStatus();
        
        return { success: true, isSubscribed, userId };
      } else {
        throw new Error('Failed to extract user ID from token');
      }
    } catch (e) {
      if (Logger.isError(e)) {
        Logger.error('Error handling Apple Auth callback:', e);
      } else {
        Logger.error('Unknown error handling Apple Auth callback');
      }
      throw e;
    }
  },

  signOut: async (): Promise<boolean> => {
    try {
      localStorage.removeItem('userToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userId');
      Logger.log('User signed out successfully');
      return true;
    } catch (error) {
      if (Logger.isError(error)) {
        Logger.error('Error during sign out:', error);
      } else {
        Logger.error('Unknown error during sign out');
      }
      throw error;
    }
  },

  checkSignInStatus: async (): Promise<{ isSignedIn: boolean; userToken: string | null; userId: string | null }> => {
    try {
      const userToken = localStorage.getItem('userToken');
      const userId = localStorage.getItem('userId');

      if (userToken && userId) {
        await SubscriptionService.init(userId);
        return { isSignedIn: true, userToken, userId };
      } else {
        return { isSignedIn: false, userToken: null, userId: null };
      }
    } catch (error) {
      if (Logger.isError(error)) {
        Logger.error('Error checking sign-in status:', error);
      } else {
        Logger.error('Unknown error checking sign-in status');
      }
      return { isSignedIn: false, userToken: null, userId: null };
    }
  },
};

function generateRandomState(): string {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}