import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleAuthService } from '../services/GoogleAuthService';
import { useAuth } from '../context/AuthContext';
import Logger from '../utils/Logger';

const GoogleAuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const { setAuthState } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');

      if (code) {
        try {
          const result = await GoogleAuthService.handleAuthCallback(code);
          if (result.success && result.user) {
            Logger.log('Google authentication successful');
            setAuthState(true, result.user.id, result.user.isSubscribed);
            navigate('/', { replace: true });
          } else {
            throw new Error(result.error || 'Authentication failed');
          }
        } catch (error) {
          Logger.error('Error handling Google auth callback:', error);
          setError('Authentication failed. Please try again.');
        }
      } else {
        Logger.error('No code found in Google callback URL');
        setError('Invalid authentication response. Please try again.');
      }
    };

    handleCallback();
  }, [location, navigate, setAuthState]);

  if (error) {
    return (
      <div>
        <h2>Authentication Error</h2>
        <p>{error}</p>
        <button onClick={() => navigate('/')}>Return to Home</button>
      </div>
    );
  }

  return <div>Processing Google authentication...</div>;
};

export default GoogleAuthCallback;