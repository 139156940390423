const logs: string[] = [];
const maxLogs: number = 1000;

const log = (message: any, data?: any): void => {
  const timestamp = new Date().toISOString();
  let logEntry = `${timestamp}: ${message}`;
  
  if (data !== undefined) {
    if (typeof data === 'object' && data !== null) {
      logEntry += '\n' + JSON.stringify(data, null, 2);
    } else {
      logEntry += ' ' + data;
    }
  }
  
  console.log(logEntry);
  logs.unshift(logEntry);
  if (logs.length > maxLogs) {
    logs.pop();
  }
};

const error = (message: string, ...args: any[]): void => {
    const timestamp = new Date().toISOString();
    let logEntry = `${timestamp}: ERROR: ${message}`;
    
    if (args.length > 0) {
      args.forEach((arg) => {
        if (typeof arg === 'object' && arg !== null) {
          if (arg instanceof Error) {
            logEntry += `\nError: ${arg.message}\nStack: ${arg.stack}`;
          } else {
            logEntry += '\n' + JSON.stringify(arg, Object.getOwnPropertyNames(arg), 2);
          }
        } else {
          logEntry += ' ' + arg;
        }
      });
    }
    
    console.error(logEntry);
    logs.unshift(logEntry);
    if (logs.length > maxLogs) {
      logs.pop();
    }
  };

const warn = (message: any, data?: any): void => {
  const timestamp = new Date().toISOString();
  let logEntry = `${timestamp}: WARNING: ${message}`;
  
  if (data !== undefined) {
    if (typeof data === 'object' && data !== null) {
      logEntry += '\n' + JSON.stringify(data, null, 2);
    } else {
      logEntry += ' ' + data;
    }
  }
  
  console.warn(logEntry);
  logs.unshift(logEntry);
  if (logs.length > maxLogs) {
    logs.pop();
  }
};

const getLogs = (): string => logs.join('\n');

const getLastLogs = (count: number = 100): string => {
  return logs.slice(0, count).join('\n');
};

const sendLogs = (): void => {
  const lastLogs = getLastLogs(100);
  const body = `An issue has been encountered in Union Arena.

Please describe the problem you experienced above this line.

-----------

Log entries:


${lastLogs}`;
  openEmailWithBody(body);
};

const reportError = (errorDetails: string, cardName: string, cardNumber: string): void => {
  const email = 'support@unionarenadecks.com';
  const subject = `Union Arena Error Report - ${cardName} (${cardNumber})`;

  openEmailClient(email, subject, errorDetails);
};

const openEmailWithBody = (body: string): void => {
  const email = 'support@unionarenadecks.com';
  const subject = 'Union Arena Error Report';

  openEmailClient(email, subject, body);
};
const openEmailClient = (email: string, subject: string, body: string): void => {
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);

  const mailtoUrl = `mailto:${email}?subject=${encodedSubject}&body=${encodedBody}`;

  window.open(mailtoUrl, '_blank');
};

const isError = (error: any): error is Error => {
  return error && error instanceof Error;
};

const Logger = {
  log,
  error,
  warn,
  getLogs,
  getLastLogs,
  sendLogs,
  reportError,
  isError
};

export default Logger;
