import React from 'react';
import { Card } from '../types/Card';

interface ProcessedEffect {
  mainEffect: string;
  subEffects: string[];
  isRaid: boolean;
}

interface CardDetailsProps {
  card: Card;
}

const escapeHTML = (str: string) => {
  return str.replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

const formatEffect = (effect: string) => {
  // Escape HTML special characters
  const escapedEffect = escapeHTML(effect);

  // Bold text within [brackets] or &lt;carats&gt;
  const formattedEffect = escapedEffect.replace(/(\[.*?\]|&lt;.*?&gt;)/g, '<strong>$1</strong>');

  return formattedEffect;
};

export const CardDetails: React.FC<CardDetailsProps> = ({ card }) => {
  const isSubEffect = (effect: string) => effect.trim().startsWith('・');
  const isRaidEffect = (effect: string) => effect.startsWith('[RAID]');

  // Function to process effects and group sub-effects
  const processEffects = (effects: string[]): ProcessedEffect[] => {
    const processed: ProcessedEffect[] = [];
    let currentEffect: ProcessedEffect | null = null;
    let inRaidEffect = false;

    effects.forEach((effect) => {
      const trimmedEffect = effect.trim();
      const formattedEffect = formatEffect(trimmedEffect);

      if (isSubEffect(trimmedEffect)) {
        if (currentEffect) {
          currentEffect.subEffects.push(formattedEffect);
        }
      } else {
        if (isRaidEffect(trimmedEffect)) {
          inRaidEffect = true;
        }

        currentEffect = {
          mainEffect: formattedEffect,
          subEffects: [],
          isRaid: inRaidEffect,
        };
        processed.push(currentEffect);
      }
    });

    return processed;
  };

  // Process main effects
  const processedEffects = processEffects(card.effect || []);

  // Process trigger effects without raid indicator
  const processedTriggerEffects = processEffects(card.triggerEffect || []).map(effect => ({
    ...effect,
    isRaid: false, // Ensure raid indicator is not applied to trigger effects
  }));

  return (
    <div className="card-details">
      {/* Card Information */}
      <div className="card-info">
        <table className="stats-table">
          <tbody>
            {/* Series spanning across 3 columns */}
            <tr className="series-row">
              <td className="info-label" colSpan={3}>
                <span className="label">Series:</span> <span className="value">{card.series}</span>
              </td>
            </tr>
            {/* Second Row */}
            <tr>
              <td className="stat-cell">
                <span className="label">Energy</span>
                <span className="value">{card.energy || '-'}</span>
              </td>
              <td className="stat-cell">
                <span className="label">Action Cost</span>
                <span className="value">{card.actionCost || '-'}</span>
              </td>
              <td className="stat-cell">
                <span className="label">Energy Gen</span>
                <span className="value">{card.energyGeneration || '-'}</span>
              </td>
            </tr>
            {/* Third Row */}
            <tr>
              <td className="stat-cell">
                <span className="label">Card ID</span>
                <span className="value">{card.cardId || '-'}</span>
              </td>
              <td className="stat-cell">
                <span className="label">Power</span>
                <span className="value">{card.power || '-'}</span>
              </td>
              <td className="stat-cell">
                <span className="label">Rarity</span>
                <span className="value">{card.rarity || '-'}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Main Effects */}
      {processedEffects.length > 0 && (
        <div className="card-effects">
          <h3>Effect:</h3>
          <div className="effect-groups">
            {processedEffects.map((group, index) => (
              <div
                key={index}
                className={`effect-group ${group.isRaid ? 'raid-effect-group' : ''}`}
              >
                <div className="effect-content">
                  <div className="main-effect">
                    <p dangerouslySetInnerHTML={{ __html: group.mainEffect }} />
                  </div>
                  {group.subEffects.length > 0 && (
                    <div className="sub-effects">
                      {group.subEffects.map((subEffect, subIndex) => (
                        <div key={subIndex} className="sub-effect">
                          <p dangerouslySetInnerHTML={{ __html: subEffect }} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Trigger Effects */}
      {processedTriggerEffects.length > 0 && (
        <div className="card-effects">
          <h3>Trigger Effect:</h3>
          <div className="effect-groups">
            {processedTriggerEffects.map((group, index) => (
              <div key={index} className="effect-group">
                <div className="effect-content">
                  <div className="main-effect">
                    <p dangerouslySetInnerHTML={{ __html: group.mainEffect }} />
                  </div>
                  {group.subEffects.length > 0 && (
                    <div className="sub-effects">
                      {group.subEffects.map((subEffect, subIndex) => (
                        <div key={subIndex} className="sub-effect">
                          <p dangerouslySetInnerHTML={{ __html: subEffect }} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};