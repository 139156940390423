import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { AppleAuthService } from '../services/AppleAuthService';
import { GoogleAuthService } from '../services/GoogleAuthService';
import { SubscriptionService } from '../services/SubscriptionService';
import Logger from '../utils/Logger';

interface AuthContextType {
  isLoading: boolean;
  isSignedIn: boolean;
  isSubscribed: boolean;
  userId: string | null;
  handleAppleSignIn: () => Promise<void>;
  handleGoogleSignIn: () => Promise<{ success: boolean; error?: string }>;
  handleSignOut: () => Promise<void>;
  handleSubscribe: () => Promise<void>;
  setAuthState: (isSignedIn: boolean, userId: string | null, isSubscribed: boolean) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);

  const checkSubscriptionStatus = useCallback(async () => {
    const isSubscribed = await SubscriptionService.checkSubscriptionStatus();
    setIsSubscribed(isSubscribed);
    Logger.log('Subscription status:', isSubscribed ? 'Subscribed' : 'Not subscribed');
  }, []);

  const checkAuthStatusAndInitialize = useCallback(async () => {
    try {
      const appleAuthStatus = await AppleAuthService.checkSignInStatus();
      const googleAuthStatus = await GoogleAuthService.checkSignInStatus();
      
      if (appleAuthStatus.isSignedIn && appleAuthStatus.userId) {
        Logger.log('User is signed in with Apple:', appleAuthStatus.userId);
        setIsSignedIn(true);
        setUserId(appleAuthStatus.userId);
        await SubscriptionService.init(appleAuthStatus.userId);
        await checkSubscriptionStatus();
      } else if (googleAuthStatus.isSignedIn && googleAuthStatus.userId) {
        Logger.log('User is signed in with Google:', googleAuthStatus.userId);
        setIsSignedIn(true);
        setUserId(googleAuthStatus.userId);
        await SubscriptionService.init(googleAuthStatus.userId);
        await checkSubscriptionStatus();
      } else {
        Logger.log('User is not signed in');
        setIsSignedIn(false);
        setIsSubscribed(false);
        setUserId(null);
        await SubscriptionService.init(); // Initialize with anonymous user
      }
    } catch (error) {
      Logger.error('Error checking auth status:', error);
      setIsSignedIn(false);
      setIsSubscribed(false);
      setUserId(null);
      await SubscriptionService.init(); // Initialize with anonymous user
    }
  }, [checkSubscriptionStatus]);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        Logger.log("Initializing app...");
        await checkAuthStatusAndInitialize();
        setIsLoading(false);
      } catch (error) {
        Logger.error('Error initializing app:', error);
        setIsLoading(false);
      }
    };

    initializeApp();
  }, [checkAuthStatusAndInitialize]);

  const handleAppleSignIn = async () => {
    try {
      await AppleAuthService.signIn();
      // The page will redirect, so we don't need to update state here
    } catch (error) {
      Logger.error('Error during Apple sign in:', error);
    }
  };

  const handleGoogleSignIn = async (): Promise<{ success: boolean; error?: string }> => {
    try {
      const result = await GoogleAuthService.signIn();
      if (result.success) {
        // Note: We don't set any state here because the page will redirect
        return { success: true };
      } else {
        return { success: false, error: result.error };
      }
    } catch (error) {
      Logger.error('Error during Google sign in:', error);
      return { success: false, error: 'An unexpected error occurred' };
    }
  };

  const handleSignOut = async () => {
    try {
      await AppleAuthService.signOut();
      await GoogleAuthService.signOut();
      setIsSignedIn(false);
      setIsSubscribed(false);
      setUserId(null);
    } catch (error) {
      Logger.error('Error during sign out:', error);
    }
  };

  const handleSubscribe = async () => {
    try {
      const offerings = await SubscriptionService.getOfferings();
      if (offerings.length > 0) {
        const customerInfo = await SubscriptionService.purchasePackage(offerings[0]);
        if (customerInfo.entitlements.active['Union Arena Pro']) {
          setIsSubscribed(true);
          Logger.log('Subscription successful');
        }
      } else {
        Logger.error('No offerings available');
      }
    } catch (error) {
      Logger.error('Error during subscription:', error);
    }
  };

  const setAuthState = useCallback((newIsSignedIn: boolean, newUserId: string | null, newIsSubscribed: boolean) => {
    setIsSignedIn(newIsSignedIn);
    setUserId(newUserId);
    setIsSubscribed(newIsSubscribed);
    // Store auth state in localStorage
    localStorage.setItem('isSignedIn', newIsSignedIn.toString());
    localStorage.setItem('userId', newUserId || '');
    localStorage.setItem('isSubscribed', newIsSubscribed.toString());
  }, []);

  return (
    <AuthContext.Provider value={{
      isLoading,
      isSignedIn,
      isSubscribed,
      userId,
      handleAppleSignIn,
      handleGoogleSignIn,
      handleSignOut,
      handleSubscribe,
      setAuthState
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};