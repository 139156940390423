import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import simplifiedCards from '../data/simplified-cards.json';
import { CardDetails } from './CardDetails';
import '../styles/CardDetailPage.css';
import AppleAppStoreSVG from '../assets/Apple_AppStore.svg';
import UnionArenaLogo from '../assets/logo192.png';

// Define the type for a single card
interface Card {
    id: string;
    name: string;
    cardId: string;
    number: string;
    color: string;
    type: string;
    rarity: string;
    imageUrl: string;
    attribute: string;
    effect: string[];
    triggerEffect: string[];
    energy: string;
    actionCost: string;
    power: string;
    series: string;
    containsTrigger: string;
    energyGeneration: string;
    containsRaid: string;
    raidTarget: string;
}

// Assert the type of simplifiedCards
const typedSimplifiedCards: Card[] = simplifiedCards as Card[];

const CardDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [card, setCard] = useState<Card | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const foundCard = typedSimplifiedCards.find((c) => c.id === id);
        if (foundCard) {
            setCard(foundCard);
        } else {
            // Redirect to search page if card not found
            navigate('/search');
        }
    }, [id, navigate]);

    if (!card) {
        return <div>Loading...</div>;
    }

    const appUrl = `unionarenadecks://card/${id}`;
    const appStoreUrl = 'https://apps.apple.com/app/deck-builder-for-union-arena/id6563139619';

    return (
        <div className="card-detail-screen">
            <button className="back-button" onClick={() => navigate(-1)}>Back</button>
            <div className="card-detail-container">
                <div className="card-detail-image">
                    <img src={card.imageUrl} alt={card.name} />
                    <div className="card-info-under-image">
                        {/* Card Name */}
                        <h2 className="card-name">{card.name}</h2>
                        {/* Attribute */}
                        {card.attribute && card.attribute !== 'NULL' && (
                            <p className="card-attribute">{card.attribute}</p>
                        )}
                        {/* Card Tags */}
                        <div className="card-tags">
                            <span className={`color-tag ${card.color.toLowerCase()}`}>{card.color}</span>
                            <span className={`type-tag ${card.type.toLowerCase().replace(' ', '-')}`}>
                                {card.type}
                            </span>
                            {card.containsTrigger && card.containsTrigger !== 'false' && (
                                <span className="trigger-tag">Trigger</span>
                            )}
                            {card.containsRaid && card.containsRaid !== 'false' && (
                                <span className="raid-tag">RAID: {card.raidTarget}</span>
                            )}
                        </div>
                    </div>
                </div>
                {/* CardDetails component no longer needs to display name or attribute */}
                <CardDetails card={card} />
            </div>
            <div className="app-links">
                <a href={appUrl} className="app-link">
                    {/* Incorporate the logo into the button */}
                    <img src={UnionArenaLogo} alt="Union Arena Logo" className="union-arena-logo" />
                    Open in Union Arena
                </a>
                <a href={appStoreUrl} target="_blank" rel="noopener noreferrer" className="app-store-link">
                    <img src={AppleAppStoreSVG} alt="Download on the App Store" />
                </a>
            </div>
        </div>
    );
};

export default CardDetail;