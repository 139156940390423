import { API_KEY } from '../services/api';

export const fetchWithApiKey = async (url: string, options: RequestInit = {}): Promise<Response> => {
  const headers = new Headers(options.headers);
  headers.append('X-API-Key', API_KEY);

  const updatedOptions: RequestInit = {
    ...options,
    headers,
  };

  return fetch(url, updatedOptions);
};