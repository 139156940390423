import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/Navbar.css';
import GoogleButtonSVG from '../assets/google-signin-button.svg';
import AppleButtonPNG from '../assets/appleid_button@2x.png';
import {
  FaBars,
  FaTimes,
  FaHome,
  FaSearch,
  FaFolderOpen,
  FaThLarge,
  FaUserAlt,
} from 'react-icons/fa'; // Import icons

const Navbar: React.FC = () => {
  const {
    isSignedIn,
    isSubscribed,
    handleAppleSignIn,
    handleGoogleSignIn,
    handleSignOut,
    handleSubscribe,
    setAuthState,
  } = useAuth();
  const [showSignIn, setShowSignIn] = useState(false);
  const [signInError, setSignInError] = useState<string | null>(null);
  const signInPopupRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const storedIsSignedIn = localStorage.getItem('isSignedIn') === 'true';
    const storedUserId = localStorage.getItem('userId');
    const storedIsSubscribed = localStorage.getItem('isSubscribed') === 'true';

    if (storedIsSignedIn && storedUserId) {
      setAuthState(storedIsSignedIn, storedUserId, storedIsSubscribed);
    }
  }, [setAuthState]);

  const toggleSignIn = () => {
    setShowSignIn(!showSignIn);
    setSignInError(null);
  };

  const handleGoogleSignInClick = async () => {
    const result = await handleGoogleSignIn();
    if (!result.success && result.error) {
      setSignInError(result.error);
    }
  };

  const handleAppleSignInClick = async () => {
    try {
      await handleAppleSignIn();
    } catch (error) {
      setSignInError('An error occurred during Apple sign in');
    }
  };

  // Close the sign-in popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        signInPopupRef.current &&
        !signInPopupRef.current.contains(event.target as Node) &&
        !(event.target as Element).classList.contains('profile-button')
      ) {
        setShowSignIn(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="navbar">
      <div className="navbar-container">
        {/* Move the menu icon to the left */}
        <button className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
        <Link to="/" className="navbar-logo">
          Union Arena Decks
        </Link>

        {/* Desktop Menu */}
        <nav className="nav-menu-desktop">
          <ul>
            <li>
              <Link to="/">
                <FaHome className="nav-icon" />
                Home
              </Link>
            </li>
            <li>
              <Link to="/decks">
                <FaFolderOpen className="nav-icon" />
                My Decks
              </Link>
            </li>
            <li>
              <Link to="/collection">
                <FaThLarge className="nav-icon" />
                Collection
              </Link>
            </li>
            <li>
              <Link to="/search">
                <FaSearch className="nav-icon" />
                Search
              </Link>
            </li>
          </ul>
        </nav>

        {/* Profile Button and Subscription Status */}
        {isSignedIn && (
          <div className="subscription-status">
            {isSubscribed ? (
              <span className="subscribed">Subscribed to Union Arena Pro</span>
            ) : (
              <button className="subscribe-button" onClick={handleSubscribe}>
                Subscribe
              </button>
            )}
          </div>
        )}
        <button
          className={`profile-button ${isSignedIn ? 'signed-in' : ''}`}
          onClick={toggleSignIn}
        >
          {isSignedIn ? 'Profile' : 'Sign In'}
        </button>
        {showSignIn && (
          <div className="sign-in-popup visible" ref={signInPopupRef}>
            {isSignedIn ? (
              <div className="signed-in-status">
                <p>Signed in</p>
                <button className="signout-button" onClick={handleSignOut}>
                  Sign Out
                </button>
              </div>
            ) : (
              <div className="sign-in-options">
                {signInError && <p className="sign-in-error">{signInError}</p>}
                {/* Google Sign-In Button */}
                <button
                  className="sign-in-button"
                  onClick={handleGoogleSignInClick}
                >
                  <img
                    src={GoogleButtonSVG}
                    alt="Sign in with Google"
                    className="sign-in-logo"
                  />
                </button>
                {/* Apple Sign-In Button */}
                <button
                  className="sign-in-button"
                  onClick={handleAppleSignInClick}
                >
                  <img
                    src={AppleButtonPNG}
                    alt="Sign in with Apple"
                    className="sign-in-logo"
                  />
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Mobile Menu */}
      <nav className={`nav-menu-mobile ${isMenuOpen ? 'active' : ''}`}>
        <ul>
          <li>
            <Link to="/" onClick={toggleMenu}>
              <FaHome className="nav-icon" />
              Home
            </Link>
          </li>
          <li>
            <Link to="/decks" onClick={toggleMenu}>
              <FaFolderOpen className="nav-icon" />
              My Decks
            </Link>
          </li>
          <li>
            <Link to="/collection" onClick={toggleMenu}>
              <FaThLarge className="nav-icon" />
              Collection
            </Link>
          </li>
          <li>
            <Link to="/search" onClick={toggleMenu}>
              <FaSearch className="nav-icon" />
              Search
            </Link>
          </li>
          {isSignedIn && (
            <li>
              <button className="mobile-signout-button" onClick={handleSignOut}>
                <FaUserAlt className="nav-icon" />
                Sign Out
              </button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;