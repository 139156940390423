import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import simplifiedCards from '../data/simplified-cards.json';
import '../styles/Home.css'; // Import the CSS file
import { useWindowSize } from '../hooks/useWindowSize'; // Custom hook to get window size

// Define the type for a single card
interface Card {
  id: string;
  name: string;
  imageUrl: string;
  type: string;
  number: string;
  // Include other fields as needed
}

// Assert the type of simplifiedCards
const typedSimplifiedCards: Card[] = simplifiedCards as Card[];

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCards, setFilteredCards] = useState<Card[]>([]);
  const [collageCards, setCollageCards] = useState<Card[]>([]);
  const { width } = useWindowSize(); // Get the current window width

  // Handle search filtering
  useEffect(() => {
    if (searchTerm.trim()) {
      const searchLower = searchTerm.toLowerCase();
      const results = typedSimplifiedCards.filter((card) =>
        card.name.toLowerCase().includes(searchLower)
      );
      setFilteredCards(results.slice(0, 5)); // Limit to top 5 results
    } else {
      setFilteredCards([]);
    }
  }, [searchTerm]);

  // Select random cards for the collage
  useEffect(() => {
    let numberOfCards = 5;

    // Adjust number of cards based on screen width
    if (width >= 1200) {
      numberOfCards = 12;
    } else if (width >= 992) {
      numberOfCards = 10;
    } else if (width >= 768) {
      numberOfCards = 8;
    } else if (width >= 600) {
      numberOfCards = 6;
    } else {
      numberOfCards = 5;
    }

    // Select 'numberOfCards' random cards for the collage
    const randomCards = [...typedSimplifiedCards]
      .sort(() => 0.5 - Math.random())
      .slice(0, numberOfCards);
    setCollageCards(randomCards);
  }, [width]);

  // Event handlers
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      navigate(`/search?query=${encodeURIComponent(searchTerm.trim())}`);
    }
  };

  const handleResultClick = (id: string) => {
    navigate(`/card/${id}`);
  };

  const handleCardClick = (id: string) => {
    navigate(`/card/${id}`);
  };

  return (
    <div className="home">
      <div className="home-content">
        <h1>Union Arena Decks</h1>
        <h2>Your Ultimate Union Arena Companion</h2> {/* Added subtitle */}
        <div className="homepage-form">
          <input
            type="text"
            className="home-search"
            placeholder="Search for cards"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            autoComplete="off"
          />
          {/* Display search results */}
          {filteredCards.length > 0 && (
            <ul className="search-results">
              {filteredCards.map((card) => (
                <li
                  key={card.id}
                  className="search-result-item"
                  onClick={() => handleResultClick(card.id)}
                >
                  <img src={card.imageUrl} alt={card.name} className="result-image" />
                  <div className="result-details">
                    <span className="result-name">{card.name}</span>
                    <span className="result-type">{card.type}</span>
                    <span className="result-number">#{card.number}</span>
                    {/* Include more details as needed */}
                  </div>
                </li>
              ))}
            </ul>
          )}
          {/* Add the buttons */}
          <div className="home-buttons">
            <button
              className="home-button"
              onClick={() => navigate('/search')}
            >
              Advanced Search
            </button>
            <button
              className="home-button"
              onClick={() => {
                const randomCard =
                  typedSimplifiedCards[
                    Math.floor(Math.random() * typedSimplifiedCards.length)
                  ];
                navigate(`/card/${randomCard.id}`);
              }}
            >
              Random Card
            </button>
          </div>
        </div>
      </div>

      <div className="homepage-collage">
        <div className="homepage-collage-cards">
          {collageCards.map((card, index) => (
            <a
              key={card.id}
              href={`/card/${card.id}`}
              style={{
                left: `${(index / collageCards.length) * 100}%`,
                top:
                  width > 600
                    ? `${Math.sin((index / collageCards.length) * Math.PI) * 30}px`
                    : 'auto',
                bottom: width <= 600 ? '0' : 'auto', // Anchor to bottom on small screens
                zIndex: index,
                width: `${100 / collageCards.length}%`,
              }}
              onClick={(e) => {
                e.preventDefault();
                handleCardClick(card.id);
              }}
            >
              <img src={card.imageUrl} alt={card.name} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
