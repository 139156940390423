import Logger from '../utils/Logger';

const API_URL = 'https://api.unionarenadecks.com';
export const API_KEY = 'bcbe78ca-3154-4d6d-a5e9-9752e4139531';

const CLOUDFLARE_IMAGE_BASE_URL = 'https://imagedelivery.net/nLLmJWlJo1wAtQTpVe3X6Q';

interface Card {
  _id: string;
  filename: string;
  translation: {
    'card number': string;
    'card name': string;
    'card color': string;
    restricted?: string;
    pair_ban?: string;
  };
}

interface Deck {
  id: string;
  name: string;
  cards: Card[];
  lastModified: string;
  isDeleted?: boolean;
}

const stripExtension = (filename: string): string => {
  return filename.replace(/\.[^/.]+$/, "");
};

export const getCloudflareImageUrl = (cardFilename: string, quality = 'auto', width = 300): string => {
  const strippedFilename = stripExtension(cardFilename);
  return `${CLOUDFLARE_IMAGE_BASE_URL}/${strippedFilename}/public?quality=${quality}&width=${width}&format=jpg`;
};

export const getLowQualityImageUrl = (cardFilename: string): string => {
  return getCloudflareImageUrl(cardFilename, 'low', 100);
};

export const getHighQualityImageUrl = (cardFilename: string): string => {
  return getCloudflareImageUrl(cardFilename, 'high', 600);
};

const fetchWithApiKey = async (url: string, options: RequestInit = {}): Promise<Response> => {
  const headers = {
    ...options.headers,
    'X-API-Key': API_KEY
  };
  const response = await fetch(url, { ...options, headers });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response;
};

const addNoCacheParam = (url: string): string => {
  const timestamp = new Date().getTime();
  return `${url}${url.includes('?') ? '&' : '?'}nocache=${timestamp}`;
};

export const getCards = async (): Promise<Card[]> => {
  try {
    const url = addNoCacheParam(`${API_URL}/cards`);
    const response = await fetchWithApiKey(url);
    const cards = await response.json();
    Logger.log(`Fetched ${cards.length} cards`);
    return cards;
  } catch (error) {
    Logger.error('Error fetching cards:', error);
    throw error;
  }
};

export const checkForUpdates = async (): Promise<{ needsCardDataUpdate: boolean; upToDate?: boolean }> => {
  try {
    const serverTimestamp = await getServerUpdateTimestamp();
    const localTimestamp = localStorage.getItem('lastUpdateTimestamp');

    if (!localTimestamp) {
      Logger.log('No local update timestamp found, card data update required');
      return { needsCardDataUpdate: true };
    }

    if (new Date(serverTimestamp) > new Date(localTimestamp)) {
      Logger.log('Server has new data, card data update required');
      return { needsCardDataUpdate: true };
    }

    Logger.log('No updates found, app is up to date');
    return { needsCardDataUpdate: false, upToDate: true };
  } catch (error) {
    Logger.error(`Error checking for updates: ${error}`);
    throw error;
  }
};

export const getServerUpdateTimestamp = async (): Promise<string> => {
  try {
    const url = addNoCacheParam(`${API_URL}/update-timestamps`);
    const response = await fetchWithApiKey(url);
    const timestamps = await response.json();

    if (!Array.isArray(timestamps) || timestamps.length === 0) {
      throw new Error('Invalid server response for update timestamps');
    }

    const serverTimestamp = timestamps[0].timestamp;
    Logger.log(`Fetched server update timestamp: ${serverTimestamp}`);
    return serverTimestamp;
  } catch (error) {
    Logger.error(`Error fetching server update timestamp: ${error}`);
    throw error;
  }
};

export const saveUpdateTimestamp = (timestamp: string): void => {
  localStorage.setItem('lastUpdateTimestamp', timestamp);
  Logger.log(`Saved update timestamp: ${timestamp}`);
};

export const syncDecksWithServer = async (decksToSync: Deck[], token: string): Promise<Deck[]> => {
  try {
    Logger.log(`Syncing decks with server: ${decksToSync.length} decks`);
    const decksArray = Array.isArray(decksToSync) ? decksToSync : [decksToSync];
    const syncedDecks: Deck[] = [];

    for (const deck of decksArray) {
      const sanitizedDeck = {
        ...deck,
        cards: Array.isArray(deck.cards) ? deck.cards.map(card => ({
          ...card,
          filename: card.filename,
        })) : []
      };

      const response = await fetch('https://api.unionarenadecks.com/sync-decks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-API-Key': API_KEY
        },
        body: JSON.stringify({ decks: [sanitizedDeck] })
      });

      const responseText = await response.text();
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        Logger.error(`Server responded with non-JSON content for deck ${deck.id}: ${responseText.substring(0, 200)}...`);
        throw new Error('Server responded with invalid JSON');
      }

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status} for deck ${deck.id}: ${data.message || 'Unknown error'}`);
      }

      Logger.log(`Deck ${deck.id} synced successfully`);
      syncedDecks.push(sanitizedDeck);
    }

    Logger.log(`All decks synced successfully: ${syncedDecks.length} decks`);
    return syncedDecks;
  } catch (error) {
    Logger.error(`Error syncing decks: ${error}`);
    throw error;
  }
};

export const fetchDeletedDecksFromServer = async (token: string): Promise<Deck[]> => {
  try {
    const response = await fetch('https://api.unionarenadecks.com/fetch-deleted-decks', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-API-Key': API_KEY
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch deleted decks from server');
    }

    const data = await response.json();
    return data.decks;
  } catch (error) {
    Logger.error('Error fetching deleted decks:', error);
    throw error;
  }
};

export const deleteDeckOnServer = async (deckId: string, token: string): Promise<boolean> => {
  try {
    Logger.log(`Deleting deck on server: ${deckId}`);
    const response = await fetch('https://api.unionarenadecks.com/delete-deck', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-API-Key': API_KEY
      },
      body: JSON.stringify({ deckId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Server responded with ${response.status}: ${errorData.message || 'Unknown error'}`);
    }

    Logger.log(`Deck ${deckId} deleted successfully on server`);
    return true;
  } catch (error) {
    Logger.error(`Error deleting deck on server: ${error}`);
    throw error;
  }
};

export const createSharedDeck = async (deckId: string, token: string): Promise<string> => {
  try {
    const response = await fetch(`${API_URL}/create-shared-deck`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-API-Key': API_KEY
      },
      body: JSON.stringify({ deckId })
    });

    if (!response.ok) {
      throw new Error('Failed to create shared deck');
    }

    const data = await response.json();
    return data.shareId;
  } catch (error) {
    Logger.error('Error creating shared deck:', error);
    throw error;
  }
};

export const fetchSharedDeck = async (shareId: string): Promise<Deck | null> => {
  try {
    const response = await fetch(`${API_URL}/shared-deck/${shareId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': API_KEY
      }
    });

    if (!response.ok) {
      if (response.status === 404) {
        return null;
      }
      throw new Error('Failed to fetch shared deck');
    }

    return await response.json();
  } catch (error) {
    Logger.error(`Error fetching shared deck: ${error}`);
    throw error;
  }
};

export const getUserProfile = async (userToken: string): Promise<any> => {
  const response = await fetch('https://api.unionarenadecks.com/user-profile', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${userToken}`,
      'Content-Type': 'application/json',
      'X-API-Key': API_KEY
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user profile');
  }

  return response.json();
};

export const addCardToCollection = async (card: Card, token: string): Promise<any> => {
  try {
    const response = await fetch(`${API_URL}/collection/add-card`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-API-Key': API_KEY
      },
      body: JSON.stringify({ card })
    });

    if (!response.ok) {
      throw new Error('Failed to add card to collection');
    }

    return await response.json();
  } catch (error) {
    Logger.error('Error adding card to collection:', error);
    throw error;
  }
};

export const deleteCardFromCollection = async (cardId: string, token: string): Promise<any> => {
  try {
    const response = await fetch(`${API_URL}/collection/delete-card`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-API-Key': API_KEY
      },
      body: JSON.stringify({ cardId })
    });

    if (!response.ok) {
      throw new Error('Failed to delete card from collection');
    }

    return await response.json();
  } catch (error) {
    Logger.error('Error deleting card from collection:', error);
    throw error;
  }
};

export const syncCollection = async (collection: Card[], token: string): Promise<any> => {
  try {
    const response = await fetch(`${API_URL}/collection/sync`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-API-Key': API_KEY
      },
      body: JSON.stringify({ collection })
    });

    if (!response.ok) {
      throw new Error('Failed to synchronize collection');
    }

    return await response.json();
  } catch (error) {
    Logger.error('Error synchronizing collection:', error);
    throw error;
  }
};

export const fetchServerCollection = async (token: string): Promise<any> => {
  try {
    const response = await fetch(`${API_URL}/collection`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-API-Key': API_KEY
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch server collection');
    }

    return await response.json();
  } catch (error) {
    Logger.error('Error fetching server collection:', error);
    throw error;
  }
};

export const incrementUserScanCount = async (token: string, count: number): Promise<void> => {
  try {
    console.log('Sending increment scan count request with count:', count);
    const timestamp = new Date().toISOString();
    const response = await fetch(`${API_URL}/user/increment-scan-count`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-API-Key': API_KEY
      },
      body: JSON.stringify({ count, timestamp })
    });

    if (!response.ok) {
      throw new Error('Failed to increment scan count');
    }

    Logger.log('User scan count incremented successfully');
  } catch (error) {
    Logger.error(`Error incrementing user scan count: ${error}`);
    throw error;
  }
};

export const getUserScanInfo = async (token: string): Promise<any> => {
  try {
    const response = await fetch(`${API_URL}/user/scan-info`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-API-Key': API_KEY
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user scan info');
    }

    return await response.json();
  } catch (error) {
    Logger.error(`Error fetching user scan info: ${error}`);
    throw error;
  }
};