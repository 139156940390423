import React from 'react';
import { AuthProvider } from './context/AuthContext';
import Navbar from './components/Navbar';
import './App.css';

interface AppProps {
  children: React.ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  return (
    <AuthProvider>
      <div className="App">
        <Navbar />
        <main>
          {children}
        </main>
      </div>
    </AuthProvider>
  );
};

export default App;