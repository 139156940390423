// src/services/GoogleAuthService.ts
import Logger from '../utils/Logger';
import { SubscriptionService } from './SubscriptionService';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID || '';
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';
const CLIENT_URL = process.env.REACT_APP_CLIENT_URL || '';

Logger.log('GOOGLE_CLIENT_ID', GOOGLE_CLIENT_ID);
Logger.log('SERVER_URL', SERVER_URL);
Logger.log('CLIENT_URL', CLIENT_URL);

interface SignInResult {
  success: boolean;
  error?: string;
}

interface AuthCallbackResult {
  success: boolean;
  accessToken?: string;
  refreshToken?: string;
  user?: {
    id: string;
    email: string;
    name: string;
    isSubscribed: boolean;
  };
  error?: string;
}

export const GoogleAuthService = {
  loadGoogleSDK: (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (typeof window.google !== 'undefined') {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load Google SDK'));
      document.body.appendChild(script);
    });
  },

  signIn: async (): Promise<SignInResult> => {
    try {
      await GoogleAuthService.loadGoogleSDK();

      return new Promise((resolve) => {
        const client = window.google.accounts.oauth2.initCodeClient({
          client_id: GOOGLE_CLIENT_ID,
          scope: 'email profile',
          ux_mode: 'redirect',
          redirect_uri: `${CLIENT_URL}/auth/google/callback`,
        });

        client.requestCode();
        resolve({ success: true });
      });
    } catch (e) {
      Logger.error('Error initiating Google Sign In:', e);
      return { success: false, error: 'Failed to initiate Google Sign In' };
    }
  },

  handleAuthCallback: async (code: string): Promise<AuthCallbackResult> => {
    try {
      const response = await fetch(`${SERVER_URL}/auth/google/callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to authenticate with the server');
      }

      const data = await response.json();
      
      // Initialize SubscriptionService and check subscription status
      await SubscriptionService.init(data.user.id);
      const isSubscribed = await SubscriptionService.checkSubscriptionStatus();

      // Update user data with subscription status
      const userData = {
        ...data.user,
        isSubscribed,
      };

      // Store auth data in localStorage
      localStorage.setItem('userToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('userId', userData.id);
      localStorage.setItem('isSignedIn', 'true');
      localStorage.setItem('isSubscribed', isSubscribed.toString());

      return {
        success: true,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        user: userData,
      };
    } catch (error) {
      Logger.error('Error during Google auth callback:', error);
      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'An unknown error occurred' 
      };
    }
  },

  signOut: async (): Promise<boolean> => {
    try {
      localStorage.removeItem('userToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('isSignedIn');
      localStorage.removeItem('isSubscribed');
      Logger.log('User signed out successfully');
      return true;
    } catch (error) {
      Logger.error('Error during sign out:', error);
      return false;
    }
  },

  checkSignInStatus: async (): Promise<{ isSignedIn: boolean; userToken: string | null; userId: string | null; isSubscribed: boolean }> => {
    try {
      const userToken = localStorage.getItem('userToken');
      const userId = localStorage.getItem('userId');
      const isSignedIn = localStorage.getItem('isSignedIn') === 'true';

      if (userToken && userId && isSignedIn) {
        // Initialize SubscriptionService and check subscription status
        await SubscriptionService.init(userId);
        const isSubscribed = await SubscriptionService.checkSubscriptionStatus();
        
        // Update localStorage with the latest subscription status
        localStorage.setItem('isSubscribed', isSubscribed.toString());

        return { isSignedIn: true, userToken, userId, isSubscribed };
      } else {
        return { isSignedIn: false, userToken: null, userId: null, isSubscribed: false };
      }
    } catch (error) {
      Logger.error('Error checking sign-in status:', error);
      return { isSignedIn: false, userToken: null, userId: null, isSubscribed: false };
    }
  },
};

// Add this to ensure TypeScript recognizes the google property on window
declare global {
  interface Window {
    google: any;
  }
}