import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppleAuthService } from '../services/AppleAuthService';
import Logger from '../utils/Logger';

const AppleAuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const accessToken = searchParams.get('accessToken');

      if (accessToken) {
        try {
          const { success, isSubscribed, userId } = await AppleAuthService.handleAuthCallback(accessToken);
          if (success && userId) {
            Logger.log('Authentication successful');
            navigate('/', { replace: true, state: { isSignedIn: true, isSubscribed, userId } });
          } else {
            throw new Error('Authentication failed or user ID not found');
          }
        } catch (error) {
          Logger.error('Error handling auth callback:', error);
          setError('Authentication failed. Please try again.');
        }
      } else {
        Logger.error('No accessToken found in callback URL');
        setError('Invalid authentication response. Please try again.');
      }
    };

    handleCallback();
  }, [location, navigate]);

  if (error) {
    return (
      <div>
        <h2>Authentication Error</h2>
        <p>{error}</p>
        <button onClick={() => navigate('/')}>Return to Home</button>
      </div>
    );
  }

  return <div>Processing authentication...</div>;
};

export default AppleAuthCallback;