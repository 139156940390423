import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  createColumnHelper,
  flexRender,
} from '@tanstack/react-table';
import simplifiedCards from '../data/simplified-cards.json';
import '../styles/Search.css';
import { useWindowSize } from '../hooks/useWindowSize';
import { CardDetails } from '../components/CardDetails';
import FilterBar from '../components/FilterBar';
import { useNavigate, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

// Define the type for a single card
interface Card {
  id: string;
  name: string;
  imageUrl: string;
  cardId: string;
  number: string;
  color: string;
  type: string;
  rarity: string;
  attribute: string;
  effect: string[];
  triggerEffect: string[];
  energy: string;
  actionCost: string;
  power: string;
  series: string;
  containsTrigger: string;
  energyGeneration: string;
  containsRaid: string;
  raidTarget: string;
}

// Assert the type of simplifiedCards
const typedSimplifiedCards: Card[] = simplifiedCards as Card[];

type ViewMode = 'tile' | 'full' | 'list';

const CARDS_PER_PAGE = 20;

const NAVBAR_HEIGHT = 50; // Height of the search navbar in pixels

const Search: React.FC = () => {
  const [cards, setCards] = useState<Card[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [viewMode, setViewMode] = useState<ViewMode>(() => {
    const savedViewMode = localStorage.getItem('viewMode');
    return (savedViewMode as ViewMode) || 'tile';
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const { width, height } = useWindowSize(); // Add this import
  const [filters, setFilters] = useState<{ 
    series?: string; 
    attribute?: string; 
    type?: string; 
    color?: string; 
    trigger?: string; 
    raid?: string;
    includeActionPoint?: boolean;
  }>({});
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object

  // Create a debounced function outside of useCallback
  const debouncedSaveState = useMemo(
    () =>
      debounce((state: any) => {
        localStorage.setItem('searchState', JSON.stringify(state));
      }, 500),
    []
  );

  // Memoize saveStateToLocalStorage
  const saveStateToLocalStorage = useCallback(() => {
    const state = {
      currentPage,
      searchTerm,
      viewMode,
      sorting,
      filters,
      timestamp: Date.now(),
    };
    debouncedSaveState(state);
  }, [currentPage, searchTerm, viewMode, sorting, filters, debouncedSaveState]);

  // Effect to load state from localStorage on component mount
  useEffect(() => {
    const savedState = localStorage.getItem('searchState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      const currentTime = Date.now();
      const oneHour = 60 * 60 * 1000;

      if (currentTime - parsedState.timestamp < oneHour) {
        setCurrentPage(parsedState.currentPage);
        setSearchTerm(parsedState.searchTerm);
        setViewMode(parsedState.viewMode);
        setSorting(parsedState.sorting);
        setFilters(parsedState.filters);
      } else {
        localStorage.removeItem('searchState');
      }
    }
  }, []);

  // Effect to save state to localStorage whenever relevant state changes
  useEffect(() => {
    saveStateToLocalStorage();
  }, [saveStateToLocalStorage]); // Add saveStateToLocalStorage to the dependency array

  // Clean up the debounced function on component unmount
  useEffect(() => {
    return () => {
      debouncedSaveState.cancel();
    };
  }, [debouncedSaveState]);

  useEffect(() => {
    const sortedCards = [...typedSimplifiedCards].sort((a, b) => a.number.localeCompare(b.number));
    setCards(sortedCards);
  }, []);

  const columnHelper = createColumnHelper<Card>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: info => (
          <div>
            {info.getValue()}
            <div>
              <span className={`color-tag ${info.row.original.color.toLowerCase()}`}>
                {info.row.original.color}
              </span>
              <span className={`type-tag ${info.row.original.type.toLowerCase().replace(' ', '-')}`}>
                {info.row.original.type}
              </span>
              {info.row.original.containsTrigger && 
               info.row.original.containsTrigger !== "false" &&
               info.row.original.containsTrigger !== "NULL" && (
                <span className="trigger-tag">Trigger</span>
              )}
              {info.row.original.containsRaid &&
               info.row.original.containsRaid !== "false" &&
               info.row.original.containsRaid !== "NULL" && (
                <span className="raid-tag">RAID: {info.row.original.raidTarget}</span>
              )}
            </div>
          </div>
        ),
        header: () => <span>Name</span>,
      }),
      columnHelper.accessor('series', {
        cell: info => info.getValue(),
        header: () => <span>Series</span>,
      }),
      columnHelper.accessor('cardId', {
        cell: info => info.getValue(),
        header: () => <span>Card ID</span>,
      }),
      columnHelper.accessor('rarity', {
        cell: info => info.getValue(),
        header: () => <span>Rarity</span>,
      }),
      columnHelper.accessor('attribute', {
        cell: info => info.getValue(),
        header: () => <span>Attribute</span>,
      }),
      columnHelper.accessor('energy', {
        cell: info => info.getValue(),
        header: () => <span>Energy</span>,
      }),
      columnHelper.accessor('actionCost', {
        cell: info => info.getValue(),
        header: () => <span>Action Cost</span>,
      }),
      columnHelper.accessor('power', {
        cell: info => info.getValue(),
        header: () => <span>Power</span>,
      }),
      columnHelper.accessor('energyGeneration', {
        cell: info => info.getValue(),
        header: () => <span>Energy Generation</span>,
      }),
      columnHelper.accessor('effect', {
        cell: info => info.getValue().join(', '),
        header: () => <span>Effect</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('triggerEffect', {
        cell: info => info.getValue().join(', '),
        header: () => <span>Trigger Effect</span>,
        enableSorting: false,
      }),
    ],
    [columnHelper]
  );

  const filteredCards = useMemo(() => {
    return cards.filter(card => {
      // Search functionality
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        const searchFields = [
          card.id,
          card.name,
          card.cardId,
          card.number,
          card.attribute,
          ...card.effect,
          ...card.triggerEffect,
          card.raidTarget
        ];
        const matchesSearch = searchFields.some(field => 
          field && field.toLowerCase().includes(searchLower)
        );
        if (!matchesSearch) {
          return false;
        }
      }
      // Other filters
      if (filters.series && card.series !== filters.series) {
        return false;
      }
      if (filters.attribute && card.attribute !== filters.attribute) {
        return false;
      }
      if (filters.type) {
        if (filters.type !== card.type) {
          return false;
        }
      } else if (card.type === 'Action Point' && !filters.includeActionPoint) {
        return false;
      }
      if (filters.color && card.color !== filters.color) {
        return false;
      }
      if (filters.trigger !== undefined) {
        if (filters.trigger === "true" && card.containsTrigger !== "true") {
          return false;
        }
        if (filters.trigger === "false" && card.containsTrigger === "true") {
          return false;
        }
      }
      if (filters.raid !== undefined) {
        if (filters.raid === "true" && card.containsRaid !== "true") {
          return false;
        }
        if (filters.raid === "false" && card.containsRaid === "true") {
          return false;
        }
      }
      return true;
    });
  }, [cards, searchTerm, filters]);

  const table = useReactTable({
    data: filteredCards,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: CARDS_PER_PAGE,
      },
    },
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
    saveStateToLocalStorage();
  };

  const handlePrevPage = () => {
    if (viewMode === 'list') {
      table.previousPage();
    } else {
      setCurrentPage(prev => Math.max(prev - 1, 1));
    }
  };

  const handleNextPage = () => {
    if (viewMode === 'list') {
      table.nextPage();
    } else {
      setCurrentPage(prev => Math.min(prev + 1, totalPages));
    }
  };

  const handleViewChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newViewMode = e.target.value as ViewMode;
    setViewMode(newViewMode);
    localStorage.setItem('viewMode', newViewMode);
    saveStateToLocalStorage();
  };

  const handleFilterChange = (newFilters: { 
    series?: string; 
    attribute?: string; 
    type?: string; 
    color?: string; 
    trigger?: string; 
    raid?: string;
    includeActionPoint?: boolean;
  }) => {
    setFilters(newFilters);
    saveStateToLocalStorage();
  };

  const handleCardClick = (id: string) => {
    navigate(`/card/${id}`);
  };

  const cardsPerPage = useMemo(() => {
    const availableHeight = height - NAVBAR_HEIGHT;
    if (viewMode === 'full') {
      const cardHeight = 300; // Adjust this value based on your design
      return Math.max(2, Math.floor(availableHeight / cardHeight) * 2);
    }
    const cardWidth = width < 768 ? 150 : width < 1200 ? 200 : 250;
    const columns = Math.floor(width / (cardWidth + 16)); // Account for gap
    const rows = Math.floor(availableHeight / (cardWidth * 1.3964 + 16)); // Account for gap
    return columns * (rows + 1); // Add one extra row to ensure scrolling
  }, [width, height, viewMode]);

  const totalPages = Math.ceil(filteredCards.length / cardsPerPage);
  const paginatedCards = filteredCards.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );

  const isFirstPage = viewMode === 'list' ? !table.getCanPreviousPage() : currentPage === 1;
  const isLastPage = viewMode === 'list' ? !table.getCanNextPage() : currentPage === totalPages;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query');
    if (query) {
      setSearchTerm(query);
    }
  }, [location.search]);

  // Add these constants inside your component, after defining `table`
  const currentDisplayedPage = viewMode === 'list' ? table.getState().pagination.pageIndex + 1 : currentPage;
  const totalDisplayedPages = viewMode === 'list' ? table.getPageCount() : totalPages;

  return (
    <div className="search-screen">
      <div className="search-navbar">
        <input
          type="text"
          placeholder="Search cards..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <select
          className="view-dropdown"
          value={viewMode}
          onChange={handleViewChange}
        >
          <option value="tile">Tile</option>
          <option value="full">Full</option>
          <option value="list">List</option>
        </select>
      </div>

      <FilterBar 
        cards={cards} 
        onFilterChange={handleFilterChange} 
        currentFilters={filters}
      />

      {/* Pagination Controls */}
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={isFirstPage}>
          ← Previous
        </button>
        <span className="page-info">
          Page <strong>{currentDisplayedPage}</strong> of <strong>{totalDisplayedPages}</strong>
        </span>
        <button onClick={handleNextPage} disabled={isLastPage}>
          Next →
        </button>
      </div>

      {/* Content */}
      {viewMode === 'tile' && (
        <div className="card-grid">
          {paginatedCards.map((card) => (
            <div key={card.id} className="card-tile" onClick={() => handleCardClick(card.id)}>
              <img src={card.imageUrl} alt={card.name} className="card-image" />
            </div>
          ))}
        </div>
      )}

      {viewMode === 'list' && (
        <div className="card-list">
          <table>
          <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <span className="sort-indicator">
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {viewMode === 'full' && (
        <div className="card-full">
          {paginatedCards.map((card) => (
            <div key={card.id} className="full-card-container">
              <div className="full-card-image" onClick={() => handleCardClick(card.id)}>
                <img src={card.imageUrl} alt={card.name} />
                {/* Add card information under the image */}
                <div className="card-info-under-image">
                  {/* Card Name */}
                  <h2 className="card-name">{card.name}</h2>
                  {/* Attribute */}
                  {card.attribute && card.attribute !== 'NULL' && (
                    <p className="card-attribute">{card.attribute}</p>
                  )}
                  {/* Card Tags */}
                  <div className="card-tags">
                    <span className={`color-tag ${card.color.toLowerCase()}`}>{card.color}</span>
                    <span className={`type-tag ${card.type.toLowerCase().replace(' ', '-')}`}>
                      {card.type}
                    </span>
                    {card.containsTrigger && card.containsTrigger !== 'false' && (
                      <span className="trigger-tag">Trigger</span>
                    )}
                    {card.containsRaid && card.containsRaid !== 'false' && (
                      <span className="raid-tag">RAID: {card.raidTarget}</span>
                    )}
                  </div>
                </div>
              </div>
              <CardDetails card={card} />
            </div>
          ))}
        </div>
      )}

      {/* Repeat Pagination Controls at the bottom */}
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={isFirstPage}>
          ← Previous
        </button>
        <span className="page-info">
          Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong>
        </span>
        <button onClick={handleNextPage} disabled={isLastPage}>
          Next →
        </button>
      </div>
    </div>
  );
};

export default Search;