import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import AppleAuthCallback from './components/AppleAuthCallback';
import GoogleAuthCallback from './components/GoogleAuthCallback';
import Search from './screens/Search';
import CardDetail from './components/CardDetailPage';
import { AuthProvider } from './context/AuthContext';
import Home from './screens/Home'; // Import Home component

const AppRouter: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <App>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth/apple/callback" element={<AppleAuthCallback />} />
            <Route path="/auth/google/callback" element={<GoogleAuthCallback />} />
            <Route path="/search" element={<Search />} />
            <Route path="/card/:id" element={<CardDetail />} />
            {/* Add any other routes your app needs */}
          </Routes>
        </App>
      </AuthProvider>
    </Router>
  );
};

export default AppRouter;