import React, { useState, useEffect, useMemo } from 'react';
import { Card } from '../types/Card';

interface FilterBarProps {
  cards: Card[];
  onFilterChange: (filters: { 
    series?: string; 
    attribute?: string; 
    type?: string; 
    color?: string; 
    trigger?: string; 
    raid?: string;
    includeActionPoint?: boolean;
  }) => void;
  currentFilters: {
    series?: string; 
    attribute?: string; 
    type?: string; 
    color?: string; 
    trigger?: string; 
    raid?: string;
    includeActionPoint?: boolean;
  };
}

const FilterBar: React.FC<FilterBarProps> = ({ cards, onFilterChange, currentFilters }) => {
  const [seriesOptions, setSeriesOptions] = useState<string[]>([]);
  const [attributeOptions, setAttributeOptions] = useState<string[]>([]);
  const [typeOptions, setTypeOptions] = useState<string[]>([]);
  const [colorOptions, setColorOptions] = useState<string[]>([]);
  const [triggerOptions, setTriggerOptions] = useState<(string | boolean)[]>([]);
  const [raidOptions, setRaidOptions] = useState<(string | boolean)[]>([]);
  const [selectedSeries, setSelectedSeries] = useState<string | undefined>(undefined);
  const [selectedAttribute, setSelectedAttribute] = useState<string | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
  const [selectedColor, setSelectedColor] = useState<string | undefined>(undefined);
  const [selectedTrigger, setSelectedTrigger] = useState<string | undefined>(undefined);
  const [selectedRaid, setSelectedRaid] = useState<string | undefined>(undefined);

  const filteredCards = useMemo(() => {
    return cards.filter(card => {
      if (selectedSeries && card.series !== selectedSeries) {
        return false;
      }
      if (selectedAttribute && card.attribute !== selectedAttribute) {
        return false;
      }
      if (selectedType && card.type !== selectedType) {
        return false;
      }
      if (selectedColor && card.color !== selectedColor) {
        return false;
      }
      if (selectedTrigger !== undefined && card.containsTrigger !== selectedTrigger) {
        return false;
      }
      if (selectedRaid !== undefined && card.containsRaid !== selectedRaid) {
        return false;
      }
      return true;
    });
  }, [cards, selectedSeries, selectedAttribute, selectedType, selectedColor, selectedTrigger, selectedRaid]);

  useEffect(() => {
    const uniqueSeries = Array.from(new Set(cards.map(card => card.series)));
    setSeriesOptions(['All', ...uniqueSeries]);

    const uniqueAttributes = Array.from(new Set(filteredCards.map(card => card.attribute).filter(attr => attr !== 'NULL'))) as string[];
    setAttributeOptions(['All', ...uniqueAttributes]);

    const uniqueTypes = Array.from(new Set(filteredCards.map(card => card.type)));
    // Remove 'Action Point' from the main list and add it as a separate option
    const mainTypes = uniqueTypes.filter(type => type !== 'Action Point');
    setTypeOptions(['All', ...mainTypes, 'Action Point']);

    const uniqueColors = Array.from(new Set(filteredCards.map(card => card.color).filter(Boolean)));
    setColorOptions(['All', ...uniqueColors]);

    setTriggerOptions(['All', 'true', 'false']);
    setRaidOptions(['All', 'true', 'false']);
  }, [filteredCards, cards]);

  useEffect(() => {
    // Update local state with current filters
    setSelectedSeries(currentFilters.series);
    setSelectedAttribute(currentFilters.attribute);
    setSelectedType(currentFilters.type);
    setSelectedColor(currentFilters.color);
    setSelectedTrigger(currentFilters.trigger);
    setSelectedRaid(currentFilters.raid);
  }, [currentFilters]);

  const handleSeriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSeries = e.target.value === 'All' ? undefined : e.target.value;
    setSelectedSeries(selectedSeries);
    onFilterChange({ series: selectedSeries, attribute: selectedAttribute, type: selectedType, color: selectedColor, trigger: selectedTrigger, raid: selectedRaid });
  };

  const handleAttributeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAttribute = e.target.value === 'All' ? undefined : e.target.value;
    setSelectedAttribute(selectedAttribute);
    onFilterChange({ series: selectedSeries, attribute: selectedAttribute, type: selectedType, color: selectedColor, trigger: selectedTrigger, raid: selectedRaid });
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = e.target.value === 'All' ? undefined : e.target.value;
    setSelectedType(selectedType);
    onFilterChange({ 
      series: selectedSeries, 
      attribute: selectedAttribute, 
      type: selectedType, 
      color: selectedColor, 
      trigger: selectedTrigger, 
      raid: selectedRaid,
      includeActionPoint: selectedType === 'Action Point'
    });
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedColor = e.target.value === 'All' ? undefined : e.target.value;
    setSelectedColor(selectedColor);
    onFilterChange({ series: selectedSeries, attribute: selectedAttribute, type: selectedType, color: selectedColor, trigger: selectedTrigger, raid: selectedRaid });
  };

  const handleTriggerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTrigger = e.target.value === 'All' ? undefined : e.target.value;
    setSelectedTrigger(selectedTrigger);
    onFilterChange({ series: selectedSeries, attribute: selectedAttribute, type: selectedType, color: selectedColor, trigger: selectedTrigger, raid: selectedRaid });
  };

  const handleRaidChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRaid = e.target.value === 'All' ? undefined : e.target.value;
    setSelectedRaid(selectedRaid);
    onFilterChange({ series: selectedSeries, attribute: selectedAttribute, type: selectedType, color: selectedColor, trigger: selectedTrigger, raid: selectedRaid });
  };

  const handleResetFilters = () => {
    setSelectedSeries(undefined);
    setSelectedAttribute(undefined);
    setSelectedType(undefined);
    setSelectedColor(undefined);
    setSelectedTrigger(undefined);
    setSelectedRaid(undefined);
    onFilterChange({ series: undefined, attribute: undefined, type: undefined, color: undefined, trigger: undefined, raid: undefined });
  };

  return (
    <div className="filter-bar">
      <div className="filter-item">
        <button className="reset-filters" onClick={handleResetFilters}>
          Reset Filters
        </button>
      </div>
      <div className="filter-item">
        <label htmlFor="series-filter">Series:</label>
        <select id="series-filter" value={selectedSeries || 'All'} onChange={handleSeriesChange}>
          {seriesOptions.map(series => (
            <option key={series} value={series}>
              {series}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-item">
        <label htmlFor="attribute-filter">Attribute:</label>
        <select id="attribute-filter" value={selectedAttribute || 'All'} onChange={handleAttributeChange}>
          {attributeOptions.map(attribute => (
            <option key={attribute} value={attribute}>
              {attribute}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-item">
        <label htmlFor="type-filter">Type:</label>
        <select id="type-filter" value={selectedType || 'All'} onChange={handleTypeChange}>
          {typeOptions.map(type => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-item">
        <label htmlFor="color-filter">Color:</label>
        <select id="color-filter" value={selectedColor || 'All'} onChange={handleColorChange}>
          {colorOptions.map(color => (
            <option key={color} value={color}>
              {color}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-item">
        <label htmlFor="trigger-filter">Trigger:</label>
        <select id="trigger-filter" value={selectedTrigger === undefined ? 'All' : selectedTrigger.toString()} onChange={handleTriggerChange}>
          {triggerOptions.map(option => (
            <option key={option.toString()} value={option.toString()}>
              {option === 'true' ? 'True' : option === 'false' ? 'False' : 'All'}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-item">
        <label htmlFor="raid-filter">Raid:</label>
        <select id="raid-filter" value={selectedRaid === undefined ? 'All' : selectedRaid.toString()} onChange={handleRaidChange}>
          {raidOptions.map(option => (
            <option key={option.toString()} value={option.toString()}>
              {option === 'true' ? 'True' : option === 'false' ? 'False' : 'All'}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FilterBar;