import { Purchases, HttpConfig, CustomerInfo, Package, PurchasesError, ErrorCode } from '@revenuecat/purchases-js';
import Logger from '../utils/Logger';
import { fetchWithApiKey } from '../utils/apiUtils';

const REVENUECAT_BILLING_PUBLIC_API_KEY = 'rcb_BnJFSQoNbAtiUNEGyYLSMtESksFy';
const SERVER_URL = 'https://api.unionarenadecks.com';

export class SubscriptionService {
  private static appUserId: string | null = null;

  static async init(appUserId?: string) {
    try {
      Logger.log('Initializing RevenueCat for Web');
      this.appUserId = appUserId || 'anonymous_user';
      
      const httpConfig: HttpConfig = {
        // Add any custom HTTP configuration here if needed
      };

      Purchases.configure(
        REVENUECAT_BILLING_PUBLIC_API_KEY,
        this.appUserId,
        httpConfig
      );

      Logger.log('RevenueCat initialized successfully');
      Logger.log('Current RevenueCat User ID:', this.appUserId);
      
      try {
        const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
        if (customerInfo.originalAppUserId) {
          Logger.log('Current RevenueCat User ID:', customerInfo.originalAppUserId);
        } else {
          Logger.log('No customer info available yet');
        }
      } catch (customerInfoError) {
        if (Logger.isError(customerInfoError)) {
          Logger.error('Error getting customer info:', customerInfoError);
        } else {
          Logger.error('Unknown error getting customer info');
        }
      }
    } catch (err) {
      if (Logger.isError(err)) {
        Logger.error('Error initializing RevenueCat:', err);
      } else {
        Logger.error('Unknown error initializing RevenueCat');
      }
    }
  }

  static async getCustomerInfo(): Promise<CustomerInfo> {
    try {
      return await Purchases.getSharedInstance().getCustomerInfo();
    } catch (err) {
      if (Logger.isError(err)) {
        Logger.error('Error getting customer info:', err);
      } else {
        Logger.error('Unknown error getting customer info');
      }
      throw err;
    }
  }

  static async getOfferings() {
    try {
      const offerings = await Purchases.getSharedInstance().getOfferings();
      Logger.log('Offerings:', offerings);
      return offerings.current ? offerings.current.availablePackages : [];
    } catch (err) {
      if (Logger.isError(err)) {
        Logger.error('Error getting offerings:', err);
      } else {
        Logger.error('Unknown error getting offerings');
      }
      return [];
    }
  }

  static async purchasePackage(pkg: Package) {
    try {
      Logger.log('Attempting to purchase package:', pkg.identifier);
      const { customerInfo } = await Purchases.getSharedInstance().purchase({
        rcPackage: pkg
      });
      Logger.log('Purchase successful:', pkg.identifier);
      Logger.log('Customer Info:', JSON.stringify(customerInfo, null, 2));
      return customerInfo;
    } catch (e) {
      if (e instanceof PurchasesError && e.errorCode === ErrorCode.UserCancelledError) {
        Logger.log('Purchase cancelled by user');
      } else if (Logger.isError(e)) {
        Logger.error('Error making purchase:', e);
      } else {
        Logger.error('Unknown error making purchase');
      }
      throw e;
    }
  }

  static async checkSubscriptionStatus() {
    try {
      const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
      
      // Check if the 'pro' entitlement is active
      const isProActive = customerInfo.entitlements.active['Union Arena Pro'] !== undefined;
      Logger.log('Subscription status checked. Pro active:', isProActive);
      return isProActive;
    } catch (err) {
      if (Logger.isError(err)) {
        Logger.error('Error checking subscription status:', err);
      } else {
        Logger.error('Unknown error checking subscription status');
      }
      return false;
    }
  }

  // Add any additional methods that require API calls here, using fetchWithApiKey
  // For example:
  static async syncSubscriptionWithServer() {
    try {
      const customerInfo = await this.getCustomerInfo();
      const response = await fetchWithApiKey(`${SERVER_URL}/sync-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerInfo }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      Logger.log('Subscription synced with server:', result);
      return result;
    } catch (error) {
      if (Logger.isError(error)) {
        Logger.error('Error syncing subscription with server:', error);
      } else {
        Logger.error('Unknown error syncing subscription with server');
      }
      throw error;
    }
  }
}